.isa-content-accordion {
  .mat-expansion-panel-header {
    position: relative;
    border-radius: 0 !important;
  }

  // todo leaving it here as a reminder to see if anything breaks
  //.mat-expansion-panel-spacing,
  //.mat-expansion-panel {
  //  margin: 8px 0 8px 0 !important;
  //}

  .mat-expansion-panel-body,
  .mat-expansion-panel {
    background: rgba(0, 0, 0, 0);
  }

  .mat-expansion-panel {
    border-radius: 0 !important;
  }

  .mat-expansion-panel-body {
    padding: 0 10px 15px !important;
  }

  .mat-expansion-panel-header {
    padding: 0 10px !important;
  }

  ::ng-deep.mat-expansion-indicator {
    line-height: 0;
  }

  ::ng-deep.mat-expansion-panel-body {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.mat-expansion-panel {
  border-radius: 0 !important;
  background-color: transparent;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  flex-grow: 0 !important;
  align-items: center;
}
