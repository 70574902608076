@use '@angular/material' as mat;
@import './config';

quill-editor {
  display: block !important;
  transition: opacity 0.15s ease-in-out;

  &.read-only {
    opacity: 0.3;

    .ql-snow .ql-formats {
      visibility: hidden;
    }

    .ql-snow .ql-editor {
      cursor: default;
    }
  }

  &:not(.read-only) {
    .ql-snow.ql-toolbar button:hover {
      color: rgba(white, 0.9);

      .ql-picker,
      .ql-stroke,
      .ql-thin {
        stroke: rgba(white, 0.9);
        color: rgba(white, 0.9);
      }

      .ql-fill {
        fill: rgba(white, 0.9);
      }
    }

    .ql-snow.ql-toolbar .ql-picker-label:hover {
      color: rgba(white, 0.9);

      .ql-picker,
      .ql-stroke,
      .ql-thin {
        stroke: rgba(white, 0.9);
        color: rgba(white, 0.9);
      }

      .ql-fill {
        fill: rgba(white, 0.9);
      }
    }
  }

  .ql-snow {
    .ql-picker,
    .ql-stroke,
    .ql-thin {
      stroke: mat.get-color-from-palette($isa-app-accent);
      color: mat.get-color-from-palette($isa-app-accent);
    }

    .ql-fill {
      fill: mat.get-color-from-palette($isa-app-accent);
    }

    .ql-toolbar .ql-picker-item.ql-selected {
      color: mat.get-color-from-palette($isa-app-accent);
    }

    .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
      fill: mat.get-color-from-palette($isa-app-accent);
    }

    .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
      stroke: mat.get-color-from-palette($isa-app-accent);
    }

    &:not(.ql-disabled) {
      .ql-editor {
        min-height: 100px;
        text-transform: none !important;
      }
    }

    .ql-editor span {
      text-transform: none !important;
    }

    .ql-editor:before {
      opacity: 0;
    }

    a {
      color: mat.get-color-from-palette($isa-app-accent);
    }

    h1,
    h2,
    h3 {
      font-weight: 500;
    }
  }

  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: 1px solid $border-color;
  }

  .ql-snow .ql-editor .ql-syntax {
    background-color: $toolbar-background-color;
  }

  .ql-snow .ql-picker-options {
    background-color: $toolbar-background-color;
  }

  div {
    text-transform: none !important;
  }
}

#ctf-task-dialog .ql-editor {
  min-height: 0px;

  .ql-syntax {
    color: #53f953;
    background-color: #23241f;
    font-family: courier;
  }
}

.light-theme {
  quill-editor .ql-snow .ql-picker,
  quill-editor .ql-snow .ql-stroke,
  quill-editor .ql-snow .ql-thin {
    stroke: #444444;
    color: #444444;
  }

  quill-editor .ql-snow .ql-fill {
    fill: #444444;
  }

  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: 1px solid $light-border;
  }

  &:not(.read-only) {
    .ql-snow.ql-toolbar button:hover {
      color: rgba(black, 0.9);

      .ql-picker,
      .ql-stroke,
      .ql-thin {
        stroke: rgba(black, 0.9);
        color: rgba(white, 0.9);
      }

      .ql-fill {
        fill: rgba(black, 0.9);
      }
    }

    .ql-snow.ql-toolbar .ql-picker-label:hover {
      color: rgba(black, 0.9);

      .ql-picker,
      .ql-stroke,
      .ql-thin {
        stroke: rgba(black, 0.9);
        color: rgba(black, 0.9);
      }

      .ql-fill {
        fill: rgba(black, 0.9);
      }
    }
  }
}
