.isa-label {
  padding: 0 3px;
  text-align: right;

  &--success {
    color: #3be965;
  }

  &--failed {
    color: #fe4a52;
  }

  &--neutral {
    color: #888888;
  }
}
