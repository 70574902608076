@use 'sass:map';
@use '@angular/material' as mat;
@use '@mat-datetimepicker/core/datetimepicker/datetimepicker-theme' as datetimepicker;
@use './table';

@mixin color($theme) {
  @include table.theme($theme);
  @include datetimepicker.mat-datetimepicker-theme($theme);

  $color-config: mat.get-color-config($theme);
  $bg: map.get($color-config, background);
  $fg: map.get($color-config, foreground);
  $primary: map.get($color-config, primary);

  $border-color: map_get($fg, dividers);
  $background-color: map_get($bg, background);
  $console-header-color: map_get($bg, status-bar);
  $toolbar-color: map_get($bg, app-bar);
  $card-color: map_get($bg, card);
  $text-color: map_get($fg, text);
  $hover-color: map_get($bg, hover);
  $primary-color: map_get($primary, 500);
  $accent-color: map_get($bg, raised-button);
  $toolbar-shadow: map_get($bg, toolbar-shadow);

  ::-webkit-scrollbar {
    background: transparent;
    border: solid 1px $border-color;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $border-color;
  }

  .isa-header,
  .isa-footer {
    box-shadow: 0 4px 22px $toolbar-shadow;
  }

  .isa-header {
    .profile-menu-button.mat-mdc-button:disabled {
      color: $text-color !important;
    }
  }

  .mat-input-element {
    caret-color: $text-color !important;
  }

  .mat-sidenav,
  .mat-drawer {
    border-right: none;
    box-shadow: 0 4px 22px $toolbar-shadow;
    background-color: $card-color;
  }

  .mat-divider {
    border-top-color: $border-color;
  }

  .isa-content-accordion {
    .mat-expansion-panel {
      background-color: $card-color;
    }
  }

  // todo fix or remove
  .login mat-progress-bar {
    .mat-progress-bar-buffer {
      background-color: $toolbar-color;
    }

    .mat-progress-bar-fill::after {
      background-color: rgba($text-color, 0.2);
    }
  }

  #task-header {
    border-bottom: 1px solid $border-color;

    #task-details {
      border-right: 1px solid $border-color;
    }

    #task-hints {
      border-left: 1px solid $border-color;
    }

    .title {
      border-bottom: 1px solid $border-color;
    }

    .current-attack {
      border-left: 1px solid $border-color;
    }
  }

  .ctf-task-dialog-content {
    .answer-text {
      border: 1px solid $border-color;
    }
  }

  #mission-board {
    .task {
      border: 1px solid $border-color;

      &:hover {
        background-color: $hover-color !important;
      }

      :not(last-child) {
        border-color: $border-color !important;
      }

      &__points,
      &__title,
      &__teams-solved {
        border-right: 1px solid $border-color;
      }
    }

    .required-task {
      background-color: $hover-color !important;
    }
  }

  #exercise-status,
  .status-bar {
    .exercise-header {
      background-color: $toolbar-color;

      & ~ .exercise-header {
        border-left: 1px solid $border-color;
      }
    }

    .status-box {
      & ~ .status-box {
        border-left: 1px solid $border-color;
      }

      .progress-container {
        background-color: $toolbar-color;
      }
    }
  }

  .incident-report .mat-subheader,
  .reporting-status .clickable-cell,
  .inject-list mat-card,
  .attack-campaign .team-status {
    &:hover {
      background-color: $hover-color;
    }
  }

  .inject-details {
    .media {
      border: 1px solid $border-color;
    }
  }

  .team-assignment {
    .not-allocated-users-header {
      background-color: $toolbar-color;
    }

    .team {
      background-color: $card-color;
    }
  }

  .exercise-not-running-warning {
    background-color: $card-color;
  }

  .team-overview-container {
    .team-name {
      border-bottom: solid 1px $border-color;
      background: $toolbar-color;
    }
  }

  isa-task-group {
    .task {
      .task-cell {
        & ~ .task-cell {
          border-left: 1px solid $border-color;
        }
      }
    }

    .task-square {
      border: 1px solid $border-color;
    }
  }

  .nice-summary {
    .included-in-exercise {
      border-color: $text-color !important;
    }
  }

  // todo move to commons to get rid of !important
  .inner-card {
    border: 1px solid $border-color !important;
  }

  .assessment-report {
    .log:nth-child(odd) {
      background-color: $toolbar-color;
    }

    .log:nth-child(even) {
      background-color: $card-color;
    }
  }

  .router-link-active {
    span {
      color: $primary-color !important;
    }

    border-left: solid 3px $primary-color !important;
  }

  .mdc-list-item:not(.mdc-list-item--disabled) {
    &:hover,
    &:focus {
      background-color: $hover-color;
    }
  }

  .dashboard-list-container {
    .mdc-list-item__secondary-text {
      line-height: normal !important;

      &::before {
        display: none !important;
      }
    }

    .mdc-list-item__start,
    .mdc-list-item--with-leading-icon:hover,
    .mdc-list-item--with-leading-icon {
      color: unset !important;
    }
  }

  .mat-mdc-dialog-container {
    color: $text-color;

    .mdc-dialog__content {
      color: unset !important;
    }
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid $border-color;

    .mdc-tab--active .mdc-tab__text-label {
      color: $text-color !important;
    }

    .mdc-tab__text-label {
      color: rgba($text-color, 0.8) !important;
    }
  }

  .mat-divider {
    border-top-color: $border-color;
  }

  .selected-list-item {
    background-color: rgba(0, 0, 0, 0.12);

    * {
      color: $primary-color !important;
    }
  }

  .mat-datetimepicker-calendar-header {
    background-color: map_get($bg, app-bar) !important;
  }

  .mat-mdc-chip {
    --mdc-chip-label-text-color: $text-color;
    --mdc-chip-with-icon-icon-color: $text-color;
    --mdc-chip-with-trailing-icon-trailing-icon-color: $text-color;
  }

  .mdc-tooltip div {
    text-transform: none;
    font-size: 11px;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    color: white;
    max-width: unset !important;
  }

  .md-content-text,
  .content-text {
    background-color: darken($card-color, 4%);
    border: 1px solid $border-color;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
