.isa-details-box {
  .row {
    padding-top: 8px;

    .label {
      font-weight: 100;
      min-width: 150px;
      display: inline-block;
      padding-bottom: 3px;
    }
  }
}
