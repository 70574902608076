.isa-form {
  height: 100%;

  .form-field {
    display: block !important;
    width: 400px;
    padding-bottom: 5px;

    &-medium {
      display: block !important;
      width: 180px;
    }

    &-full {
      display: block !important;
      width: 100%;
      padding-bottom: 5px;
    }
  }

  .form-section {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
  }

  .form-buttons {
    display: flex;
    gap: 10px;
  }

  .form-toggle {
    margin-bottom: 20px;
  }

  mat-error + mat-error {
    display: none;
  }
}
