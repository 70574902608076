@use 'sass:map';
@use '@angular/material' as mat;
@use '@cybexer/ngx-commons' as commons;
@use 'src/assets/css/themes/palette-helper';
@use 'src/assets/css/_isa-component-theme' as components;

$isa-dark-palette: (
  50: #ffffff,
  100: #e0e6eb,
  200: #c6ccd2,
  300: #596673,
  400: #3b4754,
  500: #33404d,
  600: #313d4a,
  700: #2c3844,
  800: #1f262e,
  900: #181e24,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #f9fafb,
    400: #f9fafb,
    500: #f9fafb,
    600: #f9fafb,
    700: #f9fafb,
    800: #f9fafb,
    900: #f9fafb,
    A100: #f9fafb,
    A200: #f9fafb,
    A400: #f9fafb,
    A700: #f9fafb,
  ),
);

$darker: #1f2939;
$dimmer: #223345;
$card: #2a3b4e;

// custom background and foreground palettes
$dark-theme-background: (
  status-bar: $darker,
  app-bar: $dimmer,
  background: $darker,
  hover: rgba(237, 240, 243, 0.09),
  card: $card,
  dialog: map_get($isa-dark-palette, 700),
  disabled-button: rgba(white, 0.05),
  raised-button: lighten($card, 2),
  focused-button: map_get(mat.$blue-gray-palette, 800),
  selected-button: map_get(mat.$blue-gray-palette, 900),
  selected-disabled-button: map_get(mat.$blue-gray-palette, 800),
  disabled-button-toggle: rgba(white, 0.05),
  unselected-chip: map_get($isa-dark-palette, 300),
  disabled-list-option: rgba(white, 0.05),
  tooltip: map_get($isa-dark-palette, 800),
  toolbar-shadow: rgba(black, 0.6),
);

$dark-foreground: map_get($isa-dark-palette, 50);
// todo research "on-surface" value effects
$dark-theme-foreground: (
  base: map_get($dark-theme-background, hover),
  divider: $dark-foreground,
  dividers: map_get($isa-dark-palette, 300),
  disabled: rgba(white, 0.3),
  disabled-button: rgba(white, 0.3),
  disabled-text: rgba(white, 0.3),
  hint-text: rgba(white, 0.3),
  secondary-text: rgba(white, 0.7),
  icon: $dark-foreground,
  icons: $dark-foreground,
  text: $dark-foreground,
  toggle: map_get($isa-dark-palette, 200),
  table-row: darken($card, 4),
  good: #32c559,
  warn: #da932c,
  info: #7799bd,
  error: #f44336,
);

$isa-dark-theme: palette-helper.create-custom-theme(
  palette-helper.$app-primary,
  palette-helper.$app-accent,
  palette-helper.$app-warn,
  $dark-theme-foreground,
  $dark-theme-background
);

.dark-theme {
  mat.$theme-ignore-duplication-warnings: true;

  @include mat.core-theme($isa-dark-theme);
  @include mat.all-component-themes($isa-dark-theme);
  @include commons.all-component-themes($isa-dark-theme);
  @include components.theme($isa-dark-theme);

  $bg: $dark-theme-background;
  $fg: $dark-theme-foreground;
  $border-color: map_get($fg, dividers);

  .splitter {
    .as-split-gutter-icon {
      background-color: map_get($bg, status-bar);

      &:hover {
        background-color: map_get($bg, background);
      }
    }
  }

  .dialog-splitter {
    .as-split-gutter-icon {
      background-color: map_get($bg, background);

      &:hover {
        background-color: map_get($fg, base);
      }
    }
  }

  // todo could use a bit of consolidation of themes
  .campaign-live-target {
    .target {
      border: solid 1px $border-color;
      border-top: solid 6px $border-color;

      &--not-available {
        background-color: rgba(214, 71, 79, 0.5);
      }

      &--compromised {
        background-color: rgba(218, 147, 44, 0.7);
      }

      .edit-target:hover {
        background: map_get($bg, hover);
      }
    }
  }

  .mat-datetimepicker-content {
    background-color: map_get($bg, card);
  }

  .mat-datetimepicker-calendar-table-header {
    color: map_get($fg, secondary-text);
  }

  .mat-datetimepicker-calendar-header,
  .mat-datetimepicker-content,
  .mat-datetimepicker-calendar-body-label,
  .mat-datetimepicker-calendar-body-cell-content,
  .filtered-select-search-icon {
    color: map_get($fg, text);
  }
}
