@use 'sass:map';
@use '@angular/material' as mat;
@use '@cybexer/ngx-commons' as commons;
@use 'src/assets/css/themes/palette-helper';
@use 'src/assets/css/_isa-component-theme' as components;

$isa-light-palette: (
  50: #2c3844,
  100: #33404d,
  200: #73808c,
  300: #8f99a3,
  400: #c6ccd2,
  500: #c7ccd1,
  600: #e3e6e8,
  700: #f1f2f4,
  800: #f9fafb,
  900: #ffffff,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #2c3844,
    400: #2c3844,
    500: #2c3844,
    600: #2c3844,
    700: #2c3844,
    800: #2c3844,
    900: #2c3844,
    A100: #2c3844,
    A200: #2c3844,
    A400: #2c3844,
    A700: #2c3844,
  ),
);

$light-theme-background: (
  status-bar: map_get($isa-light-palette, 400),
  app-bar: #ffffff,
  background: #ffffff,
  hover: rgba(31, 38, 46, 0.07),
  card: map_get($isa-light-palette, 800),
  dialog: map_get($isa-light-palette, 800),
  disabled-button: rgba(#000f44, 0.12),
  raised-button: #000f44,
  focused-button: rgba(black, 0.12),
  selected-button: map.get(mat.$grey-palette, 300),
  selected-disabled-button: map.get(mat.$grey-palette, 400),
  disabled-button-toggle: map.get(mat.$grey-palette, 200),
  unselected-chip: map.get(mat.$grey-palette, 300),
  disabled-list-option: map.get(mat.$grey-palette, 200),
  tooltip: map.get(mat.$grey-palette, 700),
  toolbar-shadow: rgba(black, 0.3),
);

$light-foreground: #000f44;
$light-theme-foreground: (
  base: map_get($isa-light-palette, 700),
  divider: $light-foreground,
  dividers: map_get($isa-light-palette, 600),
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.26),
  disabled-text: rgba(black, 0.38),
  elevation: black,
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: $light-foreground,
  icons: $light-foreground,
  text: $light-foreground,
  table-row: map_get($isa-light-palette, 700),
  good: #32c559,
  warn: #da932c,
  info: #7799bd,
  error: #f44336,
);

$isa-light-theme: palette-helper.create-custom-theme(
  palette-helper.$app-primary,
  palette-helper.$app-accent,
  palette-helper.$app-warn,
  $light-theme-foreground,
  $light-theme-background
);

.light-theme {
  mat.$theme-ignore-duplication-warnings: true;
  $mat-theme-ignore-duplication-warnings: true;

  @include mat.core-theme($isa-light-theme);
  @include mat.all-component-themes($isa-light-theme);
  @include commons.all-component-themes($isa-light-theme);
  @include components.theme($isa-light-theme);

  $bg: $light-theme-background;
  $fg: $light-theme-foreground;
  $border-color: map_get($fg, dividers);

  // todo: needs work from this point
  // these values should come from theme/palette
  $main-text-color: #e3e3e3;
  $primary-button-color: #d55858;

  .podium-card,
  .accordion-wrapper {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 3px 0px !important;
  }

  .podium-card {
    background-color: map_get($bg, card) !important;
  }

  .glitch-window {
    color: map_get($fg, text) !important;
    text-shadow:
      2px 0 #f5f3f3,
      -1px 0 #eb2526,
      -2px 0 #eb2526 !important;
  }

  .splitter {
    .as-split-gutter-icon {
      background-color: map_get($bg, app-bar);

      &:hover {
        background-color: map_get($bg, hover);
      }
    }
  }

  .dialog-splitter {
    .as-split-gutter-icon {
      background-color: map_get($bg, background);

      &:hover {
        background-color: map_get($fg, base);
      }
    }
  }

  .broken-server {
    fill: #333333;
  }

  .connector-type img,
  .scanner-logo,
  .connector-icon,
  .scanner-info img {
    filter: invert(1);
  }

  .error-overlay {
    .error-overlay-title {
      background-color: rgba(229, 20, 0, 0.1) !important;
    }

    .error-overlay-body {
      background-color: rgb(255, 255, 254) !important;
    }

    color: #000000 !important;
  }

  // todo could use a bit of consolidation of themes
  .campaign-live-target {
    .target {
      border: solid 1px $border-color;
      border-top: solid 6px $border-color;

      &--not-available {
        background-color: rgba(214, 71, 79, 0.9);
        color: $main-text-color;

        .broken-server {
          fill: $main-text-color;
        }
      }

      &--compromised {
        background-color: rgba(218, 147, 44, 0.9);
      }

      .edit-target:hover {
        background: map_get($bg, hover);
      }
    }
  }

  .filtered-select-search-icon {
    color: map_get($fg, text);
  }
}
